import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Fade, Portal, Stack } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
import { NavItem, NavItemDashboard } from './NavItem';
import { StyledMenu, StyledSubheader } from './styles';
export default function NavList({ item, isOffset, activeId }) {
    const { pathname } = useLocation();
    const [openMenu, setOpenMenu] = useState(false);
    const { path, children } = item;
    const { active, isExternalLink } = useActiveLink(path, activeId, false);
    useEffect(() => {
        if (openMenu) {
            handleCloseMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const handleOpenMenu = () => {
        if (children) {
            setOpenMenu(true);
        }
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: item, isOffset: isOffset, active: activeId !== '' ? path === activeId : active, open: openMenu, isExternalLink: isExternalLink, onMouseEnter: handleOpenMenu, onMouseLeave: handleCloseMenu }), !!children && openMenu && (_jsx(Portal, { children: _jsx(Fade, { in: openMenu, children: _jsx(StyledMenu, { onMouseEnter: handleOpenMenu, onMouseLeave: handleCloseMenu, children: children.map((list) => (_jsx(NavSubList, { subheader: list.subheader, items: list.items, isDashboard: list.subheader === 'Dashboard', onClose: handleCloseMenu }, list.subheader))) }) }) }))] }));
}
function NavSubList({ items, isDashboard, subheader, onClose }) {
    const { pathname } = useLocation();
    const isActive = (path) => pathname === path;
    return (_jsxs(Stack, { spacing: 2.5, gridColumn: isDashboard ? 'span 6' : 'span 2', alignItems: "flex-start", children: [_jsx(StyledSubheader, { disableSticky: true, children: subheader }), items.map((item) => isDashboard ? (_jsx(NavItemDashboard, { item: item, onClick: onClose }, item.title)) : (_jsx(NavItem, { subItem: true, item: item, active: isActive(item.path), onClick: onClose }, item.title)))] }));
}
